<template>
    <v-app>
        <v-main>
            <v-app-bar app clipped-left dense class="elevation-3" color="primary">
                <v-app-bar-nav-icon class="mx-1 black--text"><v-icon>mdi-home</v-icon></v-app-bar-nav-icon>
                <img src="/unifylogo.svg" class="pa-0 mr-3" height="15" style="cursor: pointer" />
                <v-toolbar-title class="text-h6 pa-0 mr-3 black--text">Unify</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-menu bottom left offset-y open-on-hover>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-1 black--text" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense nav>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    {{ this.$auth.userProfile.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item link @click="$vuetify.theme.dark = !$vuetify.theme.dark">
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-theme-light-dark</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ $vuetify.theme.dark ? 'Light' : 'Dark' }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item link @click="$auth.signOut()">
                            <v-list-item-icon class="mr-3">
                                <v-icon>mdi-logout-variant</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Sign Out</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-app-bar>
            <v-row class="ma-5">
                <v-col cols="6" class="hidden-md-and-down" style="padding-left: 10vw; padding-right: 10vw; padding-top: 7vh">
                    <v-carousel hide-delimiters height="100%" v-model="selectedCountryIndex" :show-arrows="false">
                        <v-carousel-item v-for="(country, i) in countries" :key="i" eager>
                            <component v-bind:is="country.mapComponent" :id="country.domId" />
                        </v-carousel-item>
                    </v-carousel>
                </v-col>
                <v-col class="v-col-auto">
                    <div class="d-flex">
                        <v-text-field v-model="searchBuilds" single-line hide-details dense rounded filled placeholder="Search" prepend-inner-icon="mdi-magnify"></v-text-field>
                        <v-menu bottom left offset-y open-on-hover align-self-center>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="ml-3 pa-0" v-bind="attrs" v-on="on">
                                    <country-flag size="normal" :country="countries[selectedCountryIndex]?.iso_3166_1_alpha_2 ?? ''" />
                                </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item-group v-model="selectedCountryIndex" color="black" mandatory>
                                    <v-list-item v-for="(country, index) in countries" :key="index">
                                        <v-list-item-title>{{ country.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                    </div>
                    <v-data-table
                        :items="countries[selectedCountryIndex]?.builds"
                        :loading="loadingBuilds"
                        class="mt-5"
                        :headers="buildsHeaders"
                        group-by="tenant.key"
                        :items-per-page="-1"
                        :search="searchBuilds"
                        sort-by="description"
                        hide-default-footer
                        mobile-breakpoint="0"
                        @click:row="handleBuildRowClick"
                    >
                        <template v-slot:[`group.header`]="{ items }">
                            <th colspan="2" class="tenantRow">
                                <div class="d-flex">
                                    <h2>{{ items[0].tenant.description }}</h2>
                                    <v-spacer />
                                    <v-icon v-if="['admin', 'super'].includes($auth.userProfile.role)" class="ma-0 pa-0 grey--text" @click="handleTenantRowClick(items[0].tenant.key)">mdi-cog</v-icon>
                                </div>
                            </th>
                        </template>
                        <template v-slot:[`item.description`]="{ item }">
                            <span class="d-flex buildRow">
                                {{ item.description }}
                            </span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-main>
    </v-app>
</template>

<script>
import gbMap from './maps/gbMap.svg'
import nlMap from './maps/nlMap.svg'
import nzMap from './maps/nzMap.svg'
import sctMap from './maps/sctMap.svg'
import dkMap from './maps/dkMap.svg'
import glMap from './maps/glMap.svg'
import cyMap from './maps/cyMap.svg'
import countryFlag from 'vue-country-flag'

export default {
    components: {
        gbMap,
        nlMap,
        nzMap,
        sctMap,
        dkMap,
        glMap,
        cyMap,
        countryFlag,
    },
    props: {
        returnUrl: {
            type: String,
        },
    },
    data() {
        return {
            countries: [],
            selectedCountryIndex: null,

            loadingBuilds: true,
            searchBuilds: '',
            buildsHeaders: [{ text: 'Description', align: 'left', sortable: true, value: 'description', width: '100%' }],
        }
    },
    created() {
        window.handleBuildBlobClick = this.handleBuildBlobClick
    },
    async mounted() {
        await this.getBuilds()
    },
    methods: {
        async getBuilds() {
            try {
                let response = await this.$slumlordApi.sites.getAll()
                let allBuilds = response.data

                let allCountries = [
                    { builds: [], name: 'Great Britain', iso_3166_1_alpha_2: 'gb', mapComponent: 'gbMap', domId: 'mapGb', latMin: 49.958232, latMax: 59.38551, longMin: -10.477891, longMax: 1.763282 },
                    { builds: [], name: 'Nederland', iso_3166_1_alpha_2: 'nl', mapComponent: 'nlMap', domId: 'mapNl', latMin: 50.75005, latMax: 53.556434, longMin: 3.358505, longMax: 7.227402 },
                    { builds: [], name: 'New Zealand', iso_3166_1_alpha_2: 'nz', mapComponent: 'nzMap', domId: 'mapNz', latMin: -47.289703, latMax: -34.394463, longMin: 166.426639, longMax: 178.550357 },
                    { builds: [], name: 'Scotland', iso_3166_1_alpha_2: 'gb-sct', mapComponent: 'sctMap', domId: 'mapSct', latMin: 54.632991, latMax: 59.385061, longMin: -7.663014, longMax: -1.764501 },
                    { builds: [], name: 'Danmark', iso_3166_1_alpha_2: 'dk', mapComponent: 'dkMap', domId: 'mapDk', latMin: 54.559722, latMax: 57.751414, longMin: 8.072262, longMax: 12.681712 },
                    { builds: [], name: 'Grønland', iso_3166_1_alpha_2: 'gl', mapComponent: 'glMap', domId: 'mapGl', latMin: 59.774047, latMax: 83.638543, longMin: -73.044589, longMax: -11.313745 },
                    { builds: [], name: 'Cyprus', iso_3166_1_alpha_2: 'cy', mapComponent: 'cyMap', domId: 'mapCy', latMin: 34.562545, latMax: 35.695186, longMin: 32.268945, longMax: 34.588562 },
                ]

                allCountries.forEach(c => (c.builds = allBuilds.filter(b => c.latMin < b.latitude && c.latMax > b.latitude && c.longMin < b.longitude && c.longMax > b.longitude)))
                allCountries.sort((a, b) => b.builds.length - a.builds.length || (a.longMax - a.longMin) * (a.latMax - a.latMin) - (b.longMax - b.longMin) * (b.latMax - b.latMin))

                if (allCountries[0]?.builds.length == allBuilds.length) allCountries.length = 1
                this.countries = allCountries.filter(c => c.builds.length)

                await null

                this.countries.forEach(c => {
                    const svgNs = 'http://www.w3.org/2000/svg'
                    const xlinkNS = 'http://www.w3.org/1999/xlink'
                    const svg = document.getElementById(c.domId)
                    const bounds = svg.viewBox.baseVal

                    c.builds.forEach(b => {
                        if (!b.latitude || !b.longitude) return

                        let mapWidth = bounds.width
                        let mapHeight = bounds.height

                        let mapLonDelta = c.longMax - c.longMin

                        let mapLatBottom = c.latMin
                        let mapLatBottomDegree = (mapLatBottom * Math.PI) / 180

                        let x = (b.longitude - c.longMin) * (mapWidth / mapLonDelta)

                        let lat = (b.latitude * Math.PI) / 180
                        let worldMapWidth = ((mapWidth / mapLonDelta) * 360) / (2 * Math.PI)
                        let mapOffsetY = (worldMapWidth / 2) * Math.log((1 + Math.sin(mapLatBottomDegree)) / (1 - Math.sin(mapLatBottomDegree)))
                        let y = mapHeight - ((worldMapWidth / 2) * Math.log((1 + Math.sin(lat)) / (1 - Math.sin(lat))) - mapOffsetY)

                        const newRect = document.createElementNS(svgNs, 'circle')
                        newRect.setAttribute('cx', x)
                        newRect.setAttribute('cy', y)
                        newRect.setAttribute('r', bounds.width / 100)
                        newRect.setAttribute('id', b.key)

                        newRect.setAttribute('class', 'buildBlob')
                        newRect.setAttribute('onclick', `handleBuildBlobClick('${b.key}')`)

                        const link = document.createElementNS(svgNs, 'a')
                        link.setAttributeNS(xlinkNS, 'title', b.description)
                        link.style.cursor = 'default'
                        link.appendChild(newRect)

                        svg.appendChild(link)
                    })
                })

                this.loadingBuilds = false
            } catch (err) {
                console.log(err)
            }
        },
        handleBuildRowClick(site) {
            window.location = new URL(site.key, this.returnUrl)
        },
        handleBuildBlobClick(buildKey) {
            window.location = new URL(buildKey, this.returnUrl)
        },
        handleTenantRowClick(tenantKey) {
            window.location = new URL(`t/${tenantKey}`, this.returnUrl)
        },
    },
}
</script>

<style>
.buildRow:hover {
    cursor: pointer;
}

.buildBlob {
    fill: #5dbb63;
    stroke: #000000;
    stroke-width: 0.05vw;
}

.buildBlob:hover {
    fill: #14689c;
    cursor: pointer;
}

.scrollable {
    overflow: scroll;
}

.tenantRow .v-icon {
    visibility: hidden;
    color: #5dbb63;
}

.tenantRow:hover .v-icon {
    visibility: visible;
    cursor: pointer;
}
</style>
